import React from 'react'

import styled from 'styled-components'

import colors from '/src/styles/ReformStyles/colors'
import text from '/src/styles/ReformStyles/text'

import AnimateFadeIn from '../animation/AnimateFadeIn'
import InViewSection from '../InViewSection'

const TableMobile = ({ tableItems }) => {

  const tableData = tableItems.rows.map((el, index) => 
      <TableContainer key={index}>
        <ColumnName>
          Category
        </ColumnName>
        <TableInfo key={index}>
          {el.rowName}
        </TableInfo>  

        <ColumnName>
          Measures
        </ColumnName>
        {el.column1.map((data, index)=> 
          <TableInfo key={index}>
            {data.tablePoint.tablePoint}
          </TableInfo>
        )} 

        <ColumnName>
          Best Practices
        </ColumnName>
        {el.column2.map((data, index)=> 
          <TableInfo key={index}>
            {data.tablePoint.tablePoint}
          </TableInfo>
        )}

      </TableContainer>   
  )

  return (
    <>
    <InViewSection>
      <AnimateFadeIn delay={0.2}>
        <TableName>
          {tableItems.tableName}
        </TableName>

        <ColoredHeader bgColor={tableItems.tableHeadingBGColor}>
          <HeaderTitle>
            {tableItems.tableHeading}
          </HeaderTitle>
        </ColoredHeader>
            {tableData}
      </AnimateFadeIn>
    </InViewSection>
    </>
  )
}

const TableName = styled.p`
  margin-top: 10.667vw;
  ${text.mobile.title3};
`
const ColoredHeader = styled.div`
  width: 92vw;
  height: 16vw;
  padding: 5.333vw 5.333vw 5.333vw 8vw;
  margin-top: 10.667vw;
  margin-bottom: 5.333vw;
  background: ${props => props.bgColor ? props.bgColor : null};
  border-radius: 0.8vw;
`
const HeaderTitle = styled.p`
  ${text.mobile.title4};
`
const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 92vw;
  border: 1px solid ${colors.light600};
  border-radius: 0.8vw;
  padding: 4vw 4vw 4vw 8vw;
  margin-bottom: 5.333vw;
`
const ColumnName = styled.h2`
  ${text.mobile.h2};
  margin-left: -4vw;
  margin-bottom: 4vw;
  margin-top: 5.333vw;
`
const TableInfo = styled.p`
  ${text.mobile.bodyS};
  display: list-item;          /* This has to be "list-item"                                               */
  list-style-type: disc;
  margin-bottom: 2.667vw;
`

export default TableMobile;