const colors = {
  orange: '#FEE4C4',
  pink: '#FCD8D3',
  purple: '#F7E2F2',
  blue: '#E0E8F0',
  turquoise: '#E0F2F0',
  light600: '#CAC4BE',
  altLight: `#5C5A59`
}

export default colors