import React from 'react'

import styled from 'styled-components'

import text from '/src/styles/ReformStyles/text'
import media from '/src/styles/ReformStyles/media'

import AnimateFadeIn from '../animation/AnimateFadeIn'
import InViewSection from '../InViewSection'


const GiantIntroAndBody = ({ gIntroAndBody }) => {

  const { heading, body, flipped } = gIntroAndBody

  return (
    <InViewSection>
      <AnimateFadeIn delay={0.2}>
        <Container>
          <GiantIntro flipped={flipped}>
            <GiantHeader>
                {heading}
            </GiantHeader>
            <GiantBody>
                {body.body}
            </GiantBody>
          </GiantIntro>
        </Container>
      </AnimateFadeIn>
    </InViewSection>
  )
}

export default GiantIntroAndBody

const Container = styled.div`
  padding: 0 23.958vw 0 22.569vw;
  width: 100vw;
  height: auto;

  ${media.fullWidth} {
    padding: 0 345px 0 325px;
    width: 1440px;
  }

  ${media.tablet} {
    margin: 0 auto;
    width: auto;
  }

  ${media.mobile} {
    padding: 0 4vw;
  }
`

const GiantIntro = styled.div`
  display: flex;
  flex-direction: ${props => props.flipped ? "row-reverse" : "row"};
  gap: 10.417vw;
  width: 54.444vw;
  left: 13.542vw;
  top: 10.417vw;
  align-items: center;

  ${media.fullWidth} {
    gap: 150px;
    width: 784px;
    left: 195px;
    top: 150px;
  }

  ${media.tablet} {
    display: flex;
    flex-direction: row;
    gap: 5.86vw;
    width: 68vw;
    margin: 0;
  }

  ${media.mobile} {
    display: flex;
    flex-direction: column;
    width: 92vw;
  }
`

const GiantHeader = styled.h2`
  ${text.desktop.h3};

  ${media.fullWidth} {
    ${text.fullWidth.h3}
  }

  ${media.tablet} {
    ${text.tablet.h3}
  }

  ${media.mobile} {
    ${text.mobile.h4}
    position: relative;
    left: -18.667vw;
    text-align: left;
  }
`

const GiantBody = styled.p`
  ${text.desktop.bodyMR}

  ${media.fullWidth} {
    ${text.fullWidth.bodyMR}
  }

  ${media.tablet} {
    ${text.tablet.bodyMR}
  }
  ${media.mobile} {
    ${text.mobile.bodyMR}
  }
`
