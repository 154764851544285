import React from 'react'

import styled from 'styled-components'

import colors from '/src/styles/ReformStyles/colors'
import text from '/src/styles/ReformStyles/text'
import media from '/src/styles/ReformStyles/media'

import AnimateFadeIn from '../animation/AnimateFadeIn'
import InViewSection from '../InViewSection'

const ColoredTable = ({cTableItems}) => {

const rowTitles = cTableItems.rowTitles.map((el, index) => 
  <TitleContainer key={index}>
    <Title key={index}>
      {el.tablePoint.tablePoint}
    </Title>
  </TitleContainer>
)

const column1Data = cTableItems.column1Rows.map((el, index) => 
  <ColumnCard key={index} bgColor={cTableItems.column1Color}>
    <ColumnPoint>
      {el.tablePoint.tablePoint}
    </ColumnPoint>
    {rowTitles[index]}
  </ColumnCard>
)

const column2Data = cTableItems.column2Rows.map((el, index, arr) => 
  <CardWrapper key={index}>
    <DividerL lastDivider={index === arr.length-1} />
      <ColumnCard bgColor={cTableItems.column2Color}>
        <ColumnPoint>
          {el.tablePoint.tablePoint}
        </ColumnPoint>
      </ColumnCard>
    <DividerR lastDivider={index === arr.length-1}/>
  </CardWrapper>
)

const column3Data = cTableItems.column3Rows.map((el, index) => 
  <ColumnCard key={index} bgColor={cTableItems.column3Color}>
    <ColumnPoint>
      {el.tablePoint.tablePoint}
    </ColumnPoint>
  </ColumnCard>
)

const columnRows = 'auto '.repeat(column1Data.length)

  return (
    <InViewSection>
      <AnimateFadeIn delay={0.2}>
        <TableContainer>
          <ColumnNames>
            {cTableItems.column1Name && <IntroContainer>
              <Name>
                {cTableItems.column1Name}
              </Name>
              <Intro1>
                {cTableItems.column1Intro}
              </Intro1>
            </IntroContainer>}
            <IntroContainer>
              <Name>
                {cTableItems.column2Name}
              </Name>
              <Intro2>
                {cTableItems.column2Intro}
              </Intro2>
            </IntroContainer>
            <IntroContainer>
              <Name>
                {cTableItems.column3Name}
              </Name>
              <Intro3>
                {cTableItems.column3Intro}
              </Intro3>
            </IntroContainer>
          </ColumnNames>
        
          <TableWrapper>
            <ColumnContainer>
              <ColumnInfo numOfRows={columnRows}>  
                  {column1Data}
                
                  {column2Data}
                  
                  {column3Data}
              </ColumnInfo>
            </ColumnContainer>
          </TableWrapper>
          
        </TableContainer>
      </AnimateFadeIn>
    </InViewSection>
  )
}

const DividerL = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  background-color: ${colors.light600};
  height: ${props => props.lastDivider ? `calc(100% - 1.042vw)` : `100%`};

  ${media.tablet} {
    height: ${props => props.lastDivider ? `calc(100% - 1.465vw)` : `100%`};
  }
`
const DividerR = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  background-color: ${colors.light600};
  height: ${props => props.lastDivider ? `calc(100% - 1.042vw)` : `100%`};

  ${media.tablet} {
    height: ${props => props.lastDivider ? `calc(100% - 1.465vw)` : `100%`};
  }
`
const TableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  left: 5.675vw;

  ${media.fullWidth} {
    left: 51px;
  }

  ${media.tablet} {
    left: 9vw;
  }
`
const TableContainer = styled.div`
  width: 72.917vw;
  height: auto;

  ${media.fullWidth} {
    width: 1000px;
  }

  ${media.tablet} {
    width: 88.086vw;
  }
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: -6.806vw;
  height: 100%;
  width: 3.611vw;

  ${media.fullWidth} {
    left: -98px;
    width: 52px;
  }

  ${media.tablet} {
    left: -11vw;
    width: 8vw;
  }
  `
const Title = styled.p`
  position: relative;
  font-family: 'Fellix';
  font-style: normal;
  font-weight: 700;
  font-size: 0.972vw;
  line-height: 1.181vw;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  
  ${media.fullWidth} {
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  
  ${media.tablet} {
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 700;
    font-size: 1.367vw;
    line-height: 1.66vw;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    width: 8vw;
  }
  `
const IntroContainer = styled.div`
  width: 20.278vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.042vw;

  ${media.fullWidth} {
    width: 292px;
    gap: 15px;
  }

  ${media.tablet} {
    width: 24.023vw;
  }
  `
const ColumnCard = styled.div`
  position: relative;
  background-color: ${props => props.bgColor};
  margin-left: 0;
  padding: 1.042vw;
  min-height: 7.292vw;
  flex-grow: 1;
  margin-bottom: 1.042vw;
  border-radius: 0.139vw;
  
  ${media.fullWidth} {
    padding: 15px;
    min-height: 105px;
    margin-bottom: 15px;
    border-radius: 2px;
  }
  
  ${media.tablet} {
    height: auto;
    width: 23.633vw;
    padding: 1.465vw;
    margin-bottom: 1.465vw;
  }
  `
  const CardWrapper = styled.div`
    position: relative;
    padding: 0vw 1.042vw 1.042vw 1.042vw;
    margin: 0vw 1.042vw;

    ${ColumnCard} {
      margin-bottom: 0vw;
      height: 100%;
    }

    ${media.fullWidth} {
      padding: 0px 15.005px 15.005px 15.005px;
      margin: 0px 15.005px;
    }

    ${media.tablet} {
      padding: 0vw 1.465vw 1.465vw 1.465vw;
      margin: 0vw 1.465vw;
    }
  `
const ColumnPoint = styled.div`
  ${text.desktop.bodyMM};
  height: auto;
  width: 18.194vw;
  text-align: left;

  ${media.fullWidth} {
    width: 262px;
    ${text.fullWidth.bodyMM};
  }

  ${media.tablet} {
    width: auto;
    ${text.tablet.bodyMM}
  }
  `
const ColumnContainer = styled.div`
  width: auto;
  height: auto;
  border: 1px solid ${colors.light600};
  border-radius: 0.278vw;
  padding: 1.042vw 1.042vw 0vw 1.042vw;

  ${media.fullWidth} {
    border-radius: 4px;
    padding: 15px 15px 0px 15px;
  }

  ${media.tablet} {
    padding: 1.465vw 1.465vw 0.37vw 1.465vw;
  }
  `
const ColumnInfo = styled.div`
  display: grid;
  grid-template-rows: ${props => props.numOfRows}; 
  grid-auto-flow: column;
  padding: 0;
  `
const Name = styled.p`
  ${text.desktop.title3}
  margin-top: 4.167vw;

  ${media.fullWidth} {
    ${text.fullWidth.title3}
    margin-top: 60px;
  }

  ${media.tablet} {
    ${text.tablet.title4}
    text-align: center;
  }
  `
const Intro1 = styled.p`
  ${text.desktop.altTitle3}
  width: 15vw;
  text-align: center;
  margin-bottom: 2.083vw;

  ${media.fullWidth} {
    ${text.fullWidth.title4}
    width: 250px;
    text-align: center;
    margin-bottom: 30px;
  }

  ${media.tablet} {
    ${text.tablet.title5}
    text-align: center;
    width: 25vw;
  }
  `

const Intro2 = styled.p`
${text.desktop.altTitle3}
width: 18vw;
text-align: center;
margin-bottom: 2.083vw;

${media.fullWidth} {
  ${text.fullWidth.title4}
  width: 292px;
  text-align: center;
  margin-bottom: 30px;
}

${media.tablet} {
  ${text.tablet.title5}
  text-align: center;
  width: 25vw;
}
`

const Intro3 = styled.p`
  ${text.desktop.altTitle3}
  width: 14.5vw;
  text-align: center;
  margin-bottom: 2.083vw;

  ${media.fullWidth} {
    ${text.fullWidth.title4}
    width: 230px;
    text-align: center;
    margin-bottom: 30px;
  }

  ${media.tablet} {
    ${text.tablet.title5}
    text-align: center;
    width: 25vw;
  }
  `
  const ColumnNames = styled.div`
    position: relative;
    width: 65.139vw;
    left: 6.7vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;  
    gap: 0vw 2.153vw; 
    justify-content: space-between;

    ${media.fullWidth} {
      width: 938px;
      left: 70px;
      gap: 0px 31px; 
    }

    ${media.tablet} {
      width: 79.883vw;
      gap: 0vw 0vw;
      left: 9.5vw;
    }
  `
export default ColoredTable