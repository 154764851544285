import React from 'react'

import styled from 'styled-components'

import text from '/src/styles/ReformStyles/text'
import media from '/src/styles/ReformStyles/media'

import AnimateFadeIn from '../animation/AnimateFadeIn'
import InViewSection from '../InViewSection'


const Body = ({bodyItem}) => {
  return (
    <InViewSection>
      <AnimateFadeIn delay={0.2}>
        <Content>
          {bodyItem}
        </Content>
      </AnimateFadeIn>
    </InViewSection>
  )
}

export default Body 

const Content = styled.p`
  ${text.desktop.bodyLM};
  margin-bottom: 1.736vw;
  width: 54.444vw;
  margin: 0 auto;
  white-space: pre-wrap;

  ${media.fullWidth} {
    ${text.fullWidth.bodyLM};
    width: 784px;
  }

  ${media.tablet} {
    ${text.tablet.bodyLM}
    width: 84vw;
  }

  ${media.mobile} {
    ${text.mobile.bodyLM}
    margin-bottom: 16vw;
    width: 92vw;
  }
`