import React from 'react'

import styled from 'styled-components'

import colors from '/src/styles/ReformStyles/colors'
import text from '/src/styles/ReformStyles/text'
import media from '/src/styles/ReformStyles/media'

import AnimateFadeIn from '../animation/AnimateFadeIn'
import InViewSection from '../InViewSection'

const Table = ({ tableItems, firstTableName }) => {

  const tableRows = tableItems.rows.map((el, index, arr) => 
    <RowNames key={index} index={index} arr={arr}>
      <TableRow index={index} arr={arr}>
        <RowName>
          {el.rowName}
        </RowName>
      </TableRow>
      <TableRow index={index} arr={arr}>
        {el.column1.map((data, index)=> 
          <TableInfo key={index}>
            {data.tablePoint.tablePoint}
          </TableInfo>
        )}
      </TableRow>
      <TableRow index={index} arr={arr}>
        {el.column2.map((data, index) => 
          <TableInfo key={index}>
            {data.tablePoint.tablePoint}
          </TableInfo>
        )}
      </TableRow>
    </RowNames>
  )


  return (
    <InViewSection>
      <AnimateFadeIn delay={0.2}>
        <Wrapper>
          {tableItems.tableName === firstTableName.tableName && 
            <TableTitle>
              {firstTableName.tableName}
            </TableTitle>
          }

          {tableItems.tableName !== firstTableName.tableName && tableItems.tableName !== null &&
            <AltTableTitle>
              {tableItems.tableName}
            </AltTableTitle>
          }

          {tableItems.column1Name && <ColumnNames>
            <ColumnName>
              {tableItems.column1Name}
            </ColumnName>
            <ColumnName>
              {tableItems.column2Name}
            </ColumnName>
            <ColumnName>
              {tableItems.column3Name}
            </ColumnName>
          </ColumnNames>
          }
          
          <ColoredHeader bgColor={tableItems.tableHeadingBGColor}>
            <HeaderTitle>
              {tableItems.tableHeading}
            </HeaderTitle>
          </ColoredHeader>

          {tableRows}
        </Wrapper>
      </AnimateFadeIn>
    </InViewSection>
  )
}

const ColumnNames = styled.div`
  display: grid;
  grid-template-columns: 12.5vw 30.208vw 30.208vw; 
  grid-template-rows: auto auto 1fr; 
  gap: 0vw 0vw; 

  ${media.fullWidth} {
    grid-template-columns: 180px 435px 435px; 
    gap: 0px 0px; 
  }

  ${media.tablet} {
    grid-template-columns: 17.578vw 35.5vw 30vw; 
  }
`

const ColumnName = styled.div`
  ${text.desktop.h1};
  margin-bottom: 1.042vw;
  margin-top: 4.167vw;

  ${media.fullWidth} {
    ${text.fullWidth.h1};
    margin-bottom: 15px;
    margin-top: 60px;
  }

  ${media.tablet} {
    ${text.tablet.h1}
  }
`

const TableTitle = styled.p`
  ${text.desktop.title2};

  ${media.fullWidth} {
    ${text.fullWidth.title2};
  }

  ${media.tablet} {
    ${text.tablet.title2}
  }
`
const AltTableTitle = styled.p`
  ${text.desktop.title2};
  margin-top: 8.333vw;

  ${media.fullWidth} {
    ${text.fullWidth.title2};
    margin-top: 120px;
  }

  ${media.tablet} {
    ${text.tablet.title2}
  }
`

const ColoredHeader = styled.div`
  width: 73.056vw;
  height: 4.861vw;
  padding: 1.389vw 47.569vw 1.389vw 2.083vw;
  background: ${props => props.bgColor ? props.bgColor : null};
  border-radius: 0.208vw;

  ${media.fullWidth} {
    width: 1052px;
    height: 70px;
    padding: 20px 685px 20px 30px;
    border-radius: 2.995px;
  }

  ${media.tablet} {
    width: 88.281vw;
    height: 6vw;
    border-radius: 0.195vw;
    padding: 1.953vw 50.098vw 1.953vw 2.93vw;
  }
`

const HeaderTitle = styled.p`
  ${text.desktop.title3};
  width: 50.472vw;

  ${media.fullWidth} {
    ${text.fullWidth.title3};
    width: 727px;
  }

  ${media.tablet} {
    ${text.tablet.title3}
  }
`

const RowNames = styled.div`
  display: grid;
  grid-template-columns: 12.5vw 30.208vw 30.208vw; 
  grid-template-rows: auto auto 1fr; 
  gap: 0px 0px; 
  border-bottom: ${props => props.index >= props.arr.length-1 ? `none` : `1px solid ${colors.light600}`};

  ${media.fullWidth} {
    display: grid;
    grid-template-columns: 180px 435px 435px; 
    gap: 0vw 0vw; 
  }

  ${media.tablet} {
    grid-template-columns: 17.578vw 35.352vw 35.352vw; 
  }
`

const RowName = styled.p`
  ${text.desktop.bodyMR};
  width: 10.417vw;
  margin-right: 2.083vw;
  margin-top: 2.083vw;
  margin-bottom: 2.083vw;

  ${media.fullWidth} {
    ${text.fullWidth.bodyMR};
    width: 150px;
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  ${media.tablet} {
    ${text.tablet.bodyMR}
    margin-right: 2.93vw;
  }
`
const TableRow = styled.div`
  height: auto;
  border: 1px solid ${colors.light600};
  border-top: none;
  border-bottom: none;
  padding-bottom: 0.694vw;
  padding-top: 0.694vw;

  ${media.fullWidth} {
    padding-bottom: 10px;
    padding-top: 10px;
  }

  ${media.tablet} {
    padding-bottom: 0.977vw;
    padding-top: 0.977vw;
  }

  :first-child {
    border-left: none;
    border-right: none;
  }

  :last-child {
    border-left: none;
    border-right: none;
  }

  overflow-wrap: word-wrap;
`
const TableInfo = styled.p`
  ${text.desktop.bodyMR};
  width: 24.722vw;
  margin-top: 2.083vw;
  margin-bottom: 2.083vw;
  margin-left: 3.403vw;
  display: list-item;          /* This has to be "list-item"                                               */
  list-style-type: disc;  

  ${media.fullWidth} {
    ${text.fullWidth.bodyMR};
    width: 356px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 49px;
  }

  ${media.tablet} {
    ${text.tablet.bodyMR};
    margin-left: 4.883vw;
    margin-right: 2.93vw;
    margin-top: 2.93vw;
    margin-bottom: 2.93vw;
  }
`
const Wrapper = styled.div`
 
`
export default Table 