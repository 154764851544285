import React from 'react'

import styled from 'styled-components'

import colors from '/src/styles/ReformStyles/colors'
import text from '/src/styles/ReformStyles/text'

import AnimateFadeIn from '../animation/AnimateFadeIn'
import InViewSection from '../InViewSection'

const ColoredTableMobile = ({ cTableItems }) => {

  const column1Data = cTableItems.rowTitles.map((el, index) => 
    <InfoContainer key={index}>
      <ContainerHeader>
        {el.tablePoint.tablePoint}
      </ContainerHeader>
      <ContainerInfo>
        {cTableItems.column1Rows[index].tablePoint.tablePoint}
      </ContainerInfo>
    </InfoContainer>
  )

  const column2Data = cTableItems.rowTitles.map((el, index) => 
    <InfoContainer key={index}>
      <ContainerHeader>
        {el.tablePoint.tablePoint}
      </ContainerHeader>
      <ContainerInfo>
        {cTableItems.column2Rows[index].tablePoint.tablePoint}
      </ContainerInfo>
    </InfoContainer>
  )

  const column3Data = cTableItems.rowTitles.map((el, index) => 
    <InfoContainer key={index}>
      <ContainerHeader>
        {el.tablePoint.tablePoint}
      </ContainerHeader>
      <ContainerInfo>
        {cTableItems.column3Rows[index].tablePoint.tablePoint}
      </ContainerInfo>
    </InfoContainer>
  )

  return (
    <>
      <InViewSection>
        <AnimateFadeIn delay={0.2}>
          <ColumnInfo bgColor={cTableItems.column1Color}>
            <Name>
              {cTableItems.column1Name}
            </Name>
            <Intro>
              {cTableItems.column1Intro}
            </Intro>
          </ColumnInfo>
          {column1Data}
          <ColumnInfo bgColor={cTableItems.column2Color}>
            <Name>
              {cTableItems.column2Name}
            </Name>
            <Intro>
              {cTableItems.column2Intro}
            </Intro>
          </ColumnInfo>
          {column2Data}
          <ColumnInfo bgColor={cTableItems.column3Color}>
            <Name>
              {cTableItems.column3Name}
            </Name>
            <Intro>
              {cTableItems.column3Intro}
            </Intro>
          </ColumnInfo>
          {column3Data}
        </AnimateFadeIn>
      </InViewSection>
    </>
  )
}

const ColumnInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.667vw;
  width: 92vw;
  height: 27.2vw;
  padding: 5.333vw 5.333vw 5.333vw 8vw;
  margin-bottom: 5.333vw;
  border-radius: 0.8vw;
  background: ${props => props.bgColor ? props.bgColor : null};
`
const Name = styled.p`
  ${text.mobile.title4}
`
const Intro = styled.p`
  ${text.mobile.title5}
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.667vw;
  width: 92vw;
  padding: 4vw;
  border: 1px solid ${colors.light600};
  border-radius: 0.8vw;
  margin-bottom: 5.333vw;
`
const ContainerInfo = styled.div`
  ${text.mobile.bodyS}
`
const ContainerHeader = styled.h3`
  ${text.mobile.altH3}
`
export default ColoredTableMobile;