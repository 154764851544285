import React from 'react'

import styled from 'styled-components'

import text from '/src/styles/ReformStyles/text'
import media from '/src/styles/ReformStyles/media'

import AnimateFadeIn from '../animation/AnimateFadeIn'
import InViewSection from '../InViewSection'

const SmallHeader = ({ smHeader }) => {
  return (
    <InViewSection>
      <AnimateFadeIn delay={0.2}>
        <Header>
          {smHeader.smallHeader}
        </Header>
      </AnimateFadeIn>
    </InViewSection>
  )
}

export default SmallHeader

const Header = styled.h4`
  ${text.desktop.h4};
  margin-bottom: 2.778vw;

  ${media.fullWidth} {
    ${text.fullWidth.h4};
     margin-bottom: 40px;
  }

  ${media.tablet} {
    ${text.tablet.h4}
  }

  ${media.mobile} {
    ${text.mobile.h4};
    margin-bottom: 10.667vw;
  }
`