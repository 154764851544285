import React from 'react'

import styled from 'styled-components'

import text from '/src/styles/ReformStyles/text'
import media from '/src/styles/ReformStyles/media'

import AnimateFadeIn from '../animation/AnimateFadeIn'
import InViewSection from '../InViewSection'

import CircleSVG from '../svgs/CircleSVG/index'


const ListCard = ({ listItem }) => {

  const {listNumber, listIntro, listBody} = listItem

  return (
    <InViewSection>
        <ListContainer>
            <AnimateFadeIn delay={0.8}>
              <ListItemContainer>
                <Circle>
                  <ListNumber>
                    {listNumber}
                  </ListNumber>
                </Circle>
                <ListIntro>
                    {listIntro.listIntro}
                </ListIntro>
                <ListBody>
                    {listBody.listBody}
                </ListBody>
              </ListItemContainer>
            </AnimateFadeIn>
        </ListContainer>
    </InViewSection>
  )
}

export default ListCard

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4.167vw;
  width: 54.444vw;
  height: auto;
  margin: 0 auto;

  ${media.fullWidth} {
    gap: 60px;
    width: 784px;
  }

  ${media.tablet} {
    margin: 0;
  }

  ${media.mobile} {
    margin: 0;
  }
  `
const ListItemContainer = styled.div`
  width: 54.444vw;
  margin-top: 4.167vw;

  ${media.fullWidth} {
    width: 784px;
    margin-top: 60px;
  }

  ${media.tablet} {
    width: 76.563vw;
    margin-top: 5.859vw;
  }

  ${media.mobile} {
    width: 92vw;
    margin-top: 16vw;
  }
`
const Circle = styled.div`
  width: 3.472vw;
  height: 3.472vw;
  border-radius: 50%;
  border: 1px solid black;
  margin-bottom: 0.694vw;

  ${media.fullWidth} {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  ${media.tablet} {
    width: 4.883vw;
    height: 4.883vw;
    margin-bottom: 0.977vw;
  }

  ${media.mobile} {
    width: 13.333vw;
    height: 13.333vw;
    margin-bottom: 2.667vw;
  }
`
const ListNumber = styled.p`
  ${text.desktop.altH4}
  text-align: center;

  ${media.fullWidth} {
    ${text.fullWidth.altH4}
  }

  ${media.tablet} {
    ${text.tablet.altH4}
  }

  ${media.mobile} {
    ${text.mobile.altH4}
  }
`
const ListIntro = styled.p`
  ${text.desktop.bodyLM}
  margin-bottom: 2.083vw;

  ${media.fullWidth} {
    ${text.fullWidth.bodyLM}
    margin-bottom: 30px;
  }

  ${media.tablet} {
    ${text.tablet.bodyLM}
  }

  ${media.mobile} {
    ${text.mobile.bodyLM}
    margin-bottom: 10.667vw;
  }
`
const ListBody = styled.p`
  ${text.desktop.bodyMR}

  ${media.fullWidth} {
    ${text.fullWidth.bodyMR}
  }

  ${media.tablet} {
    ${text.tablet.bodyMR}
  }

  ${media.mobile} {
    ${text.mobile.bodyMR}
  }
`
