import React, { useContext } from 'react'
import styled from 'styled-components'

import AnimateFadeIn from '../animation/AnimateFadeIn'
import InViewSection from '../InViewSection'
import { ScreenContext } from "../ReformProviders/Providers"

import colors from '/src/styles/ReformStyles/colors'
import media from '/src/styles/ReformStyles/media'

import ScrollSection from '../ScrollSection'

import PageTitle from '../WellbeingModules/PageTitle'
import Body from '../WellbeingModules/Body'
import ColoredTable from '../WellbeingModules/ColoredTable'
import GiantIntroAndBody from '../WellbeingModules/GiantIntroAndBody'
import ListCard from '../WellbeingModules/ListCard'
import MediumHeader from '../WellbeingModules/MediumHeader'
import SmallHeader from '../WellbeingModules/SmallHeader'
import Table from '../WellbeingModules/Table'
import TableMobile from '../WellbeingModules/TableMobile'
import ColoredTableMobile from '../WellbeingModules/ColoredTableMobile'

const SectionComponent = ({ sectionInfo, firstTableName }) => {

  const { mobile } = useContext(ScreenContext);

  const modules = sectionInfo.content.map((item, index) => {
    
    switch (item.type) {
      case 'Page Title':
        return (<PageTitle key={index} title={item.title}/>) 
      case 'Giant Intro and Body':
        return (<GiantIntroAndBody key={index} gIntroAndBody={item}/>) 
      case 'Body': 
        return (<Body key={index} bodyItem={item.content.content}/>) 
      case 'Medium Header':
        return (<MediumHeader key={index} mHeader={item}/>)  
      case 'Small Header':
        return (<SmallHeader key={index} smHeader={item}/>)
      case 'List Item': 
        return (<ListCard key={index} listItem={item}/>)
      case mobile && 'Table' : 
          return (<TableMobile key={index} tableItems={item}/>)
      case mobile && 'Colored Table': 
          return (<ColoredTableMobile key={index} cTableItems={item}/>)
      case 'Table': 
        return (<Table key={index} tableItems={item} firstTableName={firstTableName}/>)
      case 'Colored Table': 
        return (<ColoredTable key={index} cTableItems={item}/>)
      default:
        break
    }
  })
  
   return (
    <>
      <ScrollSection>
        <Container>
            {modules}
        </Container>
      </ScrollSection>
      <ScrollSection>
        <InViewSection>
          <AnimateFadeIn>
            <Divider/>
          </AnimateFadeIn>
        </InViewSection>
      </ScrollSection>
    </>
  )
}


const Divider = styled.div`
  background: ${colors.light600};
  width: 100vw;
  height: 0.069vw;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 13.889vw 0vw;

  ${media.fullWidth} {
    padding: 200px 0px;
  }

  ${media.tablet} {
    padding: 19.531vw 0vw;
  }

  ${media.mobile} {
    padding: 41vw 0vw;
  }
`

export default SectionComponent