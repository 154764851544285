import React, { useContext } from 'react'

import styled from 'styled-components'

import text from '/src/styles/ReformStyles/text'
import media from '/src/styles/ReformStyles/media'
import colors from '/src/styles/ReformStyles/colors'

import AnimateFadeIn from '../animation/AnimateFadeIn'
import InViewSection from '../InViewSection'
import PageTransitionLink from '../PageTransitionLink'
import { ScreenContext } from "../ReformProviders/Providers"

const PrevAndNext = () => {

  const { mobile } = useContext(ScreenContext);

  return (
    <InViewSection>
      <AnimateFadeIn delay={0.3}>
        <Wrapper>
          <OuterContainer>
            <Div1>
              <SmallHeader>
                Previous
              </SmallHeader>
              <BigHeader>
                Guiding Principles
              </BigHeader>
              <PageTransitionLink to='/guiding-principles' underline={false} padded={'false'}>
                <Button>
                  View Principles
                </Button>
              </PageTransitionLink>
            </Div1>
            {mobile && <Divider/>}
            <Div2 mobile={mobile}>
              <SmallHeader>
                Next
              </SmallHeader>
              <BigHeader>
                Conditionally Supported Use Cases
              </BigHeader>
              <PageTransitionLink to='/supported-use-cases' underline={false} padded={'false'}>
                <Button>
                  View Cases
                </Button>
              </PageTransitionLink>
            </Div2>
          </OuterContainer>
        </Wrapper>
      </AnimateFadeIn>
    </InViewSection>
  )
}

const OuterContainer = styled.div`
  width: 89vw;
  display: flex;
  flex-direction: row;

  ${media.fullWidth} {
    width: 1281px;
  }

  ${media.tablet} {
    width: 94vw;
  }

  ${media.mobile} {
    display: flex;
    flex-direction: column;
    width: 75vw;
  }
`
const Div1 = styled.div`
  display: flex;
  flex-direction: column;
  height: 33.194vw;
  gap: 2.083vw;
  padding: 4.167vw 5.556vw;

  ${media.fullWidth} {
    height: 477px;
    gap: 30px;
    padding: 60px 80px;
  }

  ${media.tablet} {
    height: auto;
    gap: 2.93vw;
    padding: 5.859vw 5.859vw;
  }

  ${media.mobile} {
    padding: 40vw 13.733vw 16vw 0vw;
    border-right: none;
    height: auto;
    width: 80vw;
    gap: 8vw;
  }
`
const Divider = styled.hr`
  width: 92vw;
  border-top: 1px solid ${colors.light600};
  position: relative;
  left: -9vw;

  ${media.fullWidth} {
    width: 1324px;
    left: -129px;
  }
`
const Div2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.083vw;
  padding: 4.167vw 5.556vw;
  border-left: ${props => props.mobile ? 'none':`1px solid ${colors.light600}`};

  ${media.fullWidth} {
    gap: 30px;
    padding: 60px 80px;
  }

  ${media.tablet} {
    width: 53.711vw;
    height: auto;
    gap: 2.93vw;
    padding: 5.859vw 0vw 5.859vw 5.859vw;
  }

  ${media.mobile} {
    padding: 16vw 13.733vw 40vw 0vw;
    gap: 8vw;
  }
`
const SmallHeader =styled.p`
  ${text.desktop.h1}

  ${media.fullWidth} {
    ${text.fullWidth.h1}
  }

  ${media.tablet} {
    ${text.tablet.altH1}
  }

  ${media.mobile} {
    ${text.mobile.altH3}
  }
`
const BigHeader = styled.p`
  ${text.desktop.tabH2}

  ${media.fullWidth} {
    ${text.fullWidth.tabH2}
  }

  ${media.tablet} {
    ${text.tablet.h2}
  }

  ${media.mobile} {
    ${text.mobile.h4};
  }
`
const Button = styled.div`
  ${text.desktop.title4}
  border: 1px solid black;
  width: auto;
  height: auto;
  border-radius: 13.889vw;
  text-align: center;
  padding: 1.25vw 2.222vw 1.25vw 2.222vw;

  ${media.fullWidth} {
    ${text.fullWidth.title4};
    border-radius: 200px;
    text-align: center;
    padding: 18px 32px 18px 32px;
  }
  
  ${media.tablet} {
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 1.758vw;
    line-height: 112%;
    text-transform: uppercase;
  }

  ${media.mobile} {
    width: auto;
    height: auto;
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 4.8vw;
    line-height: 112%;
    padding: 4.8vw 8.533vw 4.8vw 8.533vw;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8.333vw 12.569vw;
  height: auto;

  ${BigHeader} {
    :nth-child(1) {
      width: 22.708vw;
    }
    :nth-child(2) {
      width: 32vw;
    }
  }

  ${media.fullWidth} {
    display: flex;
    flex-direction: column;
    padding: 120px 181px;
    height: auto;

    ${BigHeader} {
      :nth-child(1) {
        width: 327px;
      }
      :nth-child(2) {
        width: 461px;
      }
    }

  }

  ${media.tablet} {
    padding: 19.531vw 0vw;
  }

`

export default PrevAndNext