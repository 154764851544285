import React, { useContext } from 'react'
import styled from 'styled-components'

import { ScreenContext } from "../ReformProviders/Providers";

import text from '/src/styles/ReformStyles/text'
import media from '/src/styles/ReformStyles/media'

import AnimateFadeIn from '../animation/AnimateFadeIn'
import InViewSection from '../InViewSection'

import BackArrow from './BackArrow'

const PageTitle = ({ title }) => {

  const { mobile } = useContext(ScreenContext);
  
  return (
    <InViewSection>
      <AnimateFadeIn delay={0.2}>
        <TitleContainer>
          <BackArrow/>
          <Title>
            {title}
          </Title>
        </TitleContainer>
      </AnimateFadeIn>
    </InViewSection>
  )
}

export default PageTitle

const Wrapper = styled.div`
  height: 36.458vw;
  width: 100vw;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70.361vw;
  gap: 1.241vw;
  margin: 0 auto;

  ${media.fullWidth} {
    width: 1013px;
    gap: 18px;
  }

  ${media.mobile} {
    width: 60vw;
  }
`
const Title = styled.h4`
  ${text.desktop.h3};

  ${media.fullWidth} {
    ${text.fullWidth.h3}
  }

  ${media.mobile} {
    ${text.mobile.h4}
  }
`