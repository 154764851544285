import React from 'react'
import gsap from 'gsap'
import { graphql } from 'gatsby'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

import styled from 'styled-components'

import SectionComponent from '../components/SectionComponent/index'
import ScrollSection from '../components/ScrollSection/index'
import Footer from '../components/Footer/index'
import PrevAndNext from '../components/PrevAndNext/index'


gsap.registerPlugin(ScrollToPlugin)

const Wellbeing = ({ data }) => {
  const {
    pageSections,
    allContentfulTable
  } = data.allContentfulPageWellBeing.edges[0].node

  const tableNamesArr = data.allContentfulTable.nodes.filter((node) => node.tableName !== null)
  const firstName = tableNamesArr[tableNamesArr.length - 1]
  
  const sections = pageSections.map((section, index) => 
    <SectionComponent 
      key={index}
      sectionInfo={section}
      firstTableName={firstName}
    />
  )

  return (
    <>
      <Wrapper>
        {sections}
        <ScrollSection>
          <PrevAndNext/>
        </ScrollSection>
      </Wrapper>
    
      <ScrollSection>
        <Footer />
      </ScrollSection>
    </>
  )
}

const Wrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default Wellbeing

export const pageQuery = graphql`
  query WellbeingQuery {
    allContentfulPageWellBeing {
    edges {
      node {
        urlSlug
        pageSections {
          sectionName
          content {
            ... on ContentfulBody {
              type
              content {
                content
              }
            }
            ... on ContentfulColoredTable {
              type
              column1Color
              column1Intro
              column1Name
              column1Rows {
                tablePoint {
                  tablePoint
                }
                colored_table {
                  column1Color
                }
              }
              column2Color
              column2Intro
              column2Name
              column2Rows {
                tablePoint {
                  tablePoint
                }
                colored_table {
                  column2Color
                }
              }
              column3Color
              column3Intro
              column3Name
              column3Rows {
                tablePoint {
                  tablePoint
                }
                colored_table {
                  column3Color
                }
              }
              rowTitles {
                tablePoint {
                  tablePoint
                }
              }
            }
            ... on ContentfulGiantHeadingAndBody {
              type
              heading
              body {
                body
              }
              flipped
            }
            ... on ContentfulListItem {
              type
              listNumber
              listIntro {
                listIntro
              }
              listBody {
                listBody
              }
            }
            ... on ContentfulMediumHeader {
              type
              mediumHeader
              position
            }
            ... on ContentfulSmallHeader {
              type
              smallHeader
              position
            }
            ... on ContentfulTable {
              type
              tableName
              tableHeading
              tableHeadingBGColor
              column1Name
              column2Name
              column3Name
              rows {
                rowName
                column1 {
                  type
                  tablePoint {
                    tablePoint
                  }
                }
                type
                column2 {
                  type
                  tablePoint {
                    tablePoint
                  }
                }
              }
            }
            ... on ContentfulPageTitle {
              type
              title
            }
          }
        }
      }
    }
  }
  allContentfulTable {
    nodes {
      tableName
    }
  }
}
`