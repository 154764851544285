
const text = {

fullWidth: {
  title2: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 112%;
  `,
  title3: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 112%;
  `,
  altTitle3: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 16.099px;
    line-height: 18.994px;
  `,
  title4: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 112%;
  `,
  h1: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 700;
    font-size: 22.003px;
    line-height: 26.006px;
  `,
  h2: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 150px;
    line-height: 100%;
  `,
  h3: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 75px;
    line-height: 100%;
  `,
  h4: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 38.002px;
    line-height: 100%;
  `,
  altH4: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 27.994px;
    line-height: 47.52px;
  `,
   bodyLR: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 400;
    font-size: 24.005px;
    line-height: 140%;
  `,
  bodyLM: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 500;
    font-size: 24.005px;
    line-height: 140%;
  `,
  bodyMR: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
  `,
  bodyMM: `
  font-family: 'Fellix';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
`,
  bodyR: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
  `,
  tabH2: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 120%;
  `,
},

desktop: {
  title2: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 2.5vw;
    line-height: 112%;
  `,
  title3: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 1.875vw;
    line-height: 112%;
  `,
  altTitle3: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 1.118vw;
    line-height: 1.319vw;
  `,
  title4: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 1.25vw;
    line-height: 112%;
  `,
  h1: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 700;
    font-size: 1.528vw;
    line-height: 1.806vw;
  `,
  h2: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 10.417vw;
    line-height: 100%;
  `,
  h3: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 5.208vw;
    line-height: 100%;
  `,
  h4: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 2.639vw;
    line-height: 100%;
  `,
  altH4: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 1.944vw;
    line-height: 3.3vw;
  `,
  bodyLR: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 400;
    font-size: 1.667vw;
    line-height: 140%;
  `,
  bodyLM: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 500;
    font-size: 1.667vw;
    line-height: 140%;
  `,
  bodyMR: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25vw;
    line-height: 140%;
  `,
  bodyMM: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 500;
    font-size: 1.25vw;
    line-height: 140%;
  `,
  bodyR: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25vw;
    line-height: 140%;
  `,
  tabH2: `
  font-family: 'Nib';
  font-style: normal;
  font-weight: 700;
  font-size: 4.167vw;
  line-height: 120%;
`,
},

tablet: {
  heading1: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 10.938vw;
    line-height: 120%;
  `,
  title2:`
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 3.516vw;
    line-height: 112%;
  `,
  title3: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 2.637vw;
    line-height: 90%;  
  `,
  title4: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 2.148vw;
    line-height: 2.539vw;
  `,
  title5: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 1.563vw;
    line-height: 1.855vw;
  `,
  altH1: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 700;
    font-size: 2.148vw;
    line-height: 2.539vw;
    text-transform: uppercase;
  `,
  h1: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 700;
    font-size: 2.148vw;
    line-height: 2.539vw;
  `,
  h2: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 5.859vw;
    line-height: 120%;
  `,
  h3:`
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 7.324vw;
    line-height: 100%;
  `,
  h4: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 3.711vw;
    line-height: 100%;
  `,
  altH4: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 2.734vw;
    line-height: 4.8vw;
  `,
  bodyLR: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 400;
    font-size: 2.344vw;
    line-height: 140%;
  `,
  bodyLM: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 500;
    font-size: 2.344vw;
    line-height: 140%;
  `,
  bodyMR: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 400;
    font-size: 1.758vw;
    line-height: 140%;
  `,
  bodyMM: `
  font-family: 'Fellix';
  font-style: normal;
  font-weight: 500;
  font-size: 1.758vw;
  line-height: 140%;
`,
},

mobile: {
  title3: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 7.2vw;
    line-height: 112%;
  `,
  title4: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 4.8vw;
    line-height: 112%;
  `,
  title5: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 600;
    font-size: 3.733vw;
    line-height: 112%;
  `,
  h2: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 700;
    font-size: 4.267vw;
    line-height: 5.067vw;
  `,
  h3: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 20vw;
    line-height: 100%;
  `,
  altH3: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 700;
    font-size: 3.2vw;
    line-height: 3.733vw;
    text-transform: uppercase;
  `,
  h4: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 10.133vw;
    line-height: 100%;
  `,
  altH4: `
    font-family: 'Nib';
    font-style: normal;
    font-weight: 700;
    font-size: 7.467vw;
    line-height: 13.2vw;
  `,
  bodyLR: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 400;
    font-size: 6.4vw;
    line-height: 140%;
  `,
  bodyLM: `
  font-family: 'Fellix';
  font-style: normal;
  font-weight: 500;
  font-size: 6.4vw;
  line-height: 140%;
`,
  bodyMR: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 400;
    font-size: 4.8vw;
    line-height: 140%; 
  `,
  bodyMM: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 500;
    font-size: 4.8vw;
    line-height: 140%; 
  `,
  bodyS: `
    font-family: 'Fellix';
    font-style: normal;
    font-weight: 400;
    font-size: 3.733vw;
    line-height: 140%;
  `,
  },
};

export default text;