import React from 'react'

import styled from 'styled-components'

import text from '/src/styles/ReformStyles/text'
import media from '/src/styles/ReformStyles/media'

import AnimateFadeIn from '../animation/AnimateFadeIn'
import InViewSection from '../InViewSection'

const MediumHeader = ({ mHeader }) => {

  return (
    <InViewSection>
      <AnimateFadeIn delay={0.2}>
        <Header left={mHeader.position}>
          {mHeader.mediumHeader}
        </Header>
      </AnimateFadeIn>
    </InViewSection>
  )
}

export default MediumHeader

const Header = styled.h3`
  ${text.desktop.h3};
  position: relative;
  margin-bottom: 2.778vw;
  width: 70vw;
  left: ${props => props.left ? `0vw` : `1.448vw`};
  text-align: ${props => props.left ? `left` : `center`};

  ${media.fullWidth} {
    ${text.fullWidth.h3};
    margin-bottom: 40px;
    width: 1008px;
    left: ${props => props.left ? `0px` : `0px`};
  }

  ${media.tablet} {
    ${text.tablet.h2};
    width: ${props => props.left ? `76.563vw` : `90vw`};
    left: ${props => props.left ? `0vw` : `0vw`};
  }

  ${media.mobile} {
    ${text.mobile.h4};
    width: 92vw;
    left: 0;
    text-align: left;
    margin-bottom: 10.667vw;
  }
`